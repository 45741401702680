export const CONTRACT_ADDRESS = "0x7a94cb4dda8c454467d41f690372ddab9755af4c";

export const IPFS_URL1 =
  "https://nftstorage.link/ipfs/bafybeibmfsh6djprouyriwebsznhb6zde3cg7xaj2x2s7uxprybmotynga/";
export const IPFS_URL2 =
  "https://nftstorage.link/ipfs/bafybeidfu5aaot7qmct3t4ex6rioey3usna64hifcb2wml6yvx5gkt7k5q/";
export const IPFS_URL3 =
  "https://nftstorage.link/ipfs/bafybeia6pn6n7f7mewzmbxsmx6yaplrlswdb7ikv5qsdhhaklylln4h5om/";

export const TOTAL = 5555;

export const MINT_TIME = 1668161460;